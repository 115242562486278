import "./App.css"
import React, { Component } from 'react'

const ENDPOINT = 'https://dmm5wlvxvj.execute-api.us-west-2.amazonaws.com/prod'
const TOKEN_ENDPOINT = 'https://haloclip.auth.us-west-2.amazoncognito.com/oauth2/token'
const redirectUri = 'https://www.haloclip.me'
// const redirectUri = 'http://localhost:3000'
const clientId = '53u66osv3fq4igri3u5eu93v4l'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      uploading: false,
      fileUploadedSuccessfully: false,
      loadingClips: true,
      clipUrls: []
    }
  }

  async componentDidMount() {
    console.log("Component mounted")

    // check if the URL contains the authorization code
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')

    if (code) {
      // remove the code from the URL to avoid processing it again on subsequent renders
      window.history.replaceState({}, document.title, window.location.pathname)

      // authorization code is present, exchange for tokens
      sessionStorage.setItem('isLoggedIn', 'true')

      await this.exchangeCodeForTokens(code)
    }

    // call onClipRequest to fetch clip URLs
    this.onClipRequest()
  }

  exchangeCodeForTokens = async (code) => {
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: clientId,
      code: code,
      redirect_uri: redirectUri
    })

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const response = await fetch(TOKEN_ENDPOINT, {
      method: "POST",
      headers: headers,
      body: params.toString()
    })

    // parse the response body as JSON
    const responseBody = await response.json()
    // console.log("id_token: ", responseBody.id_token)

    // set tokens
    sessionStorage.setItem('idToken', responseBody.id_token)
    sessionStorage.setItem('accessToken', responseBody.access_token)
    sessionStorage.setItem('refreshToken', responseBody.refresh_token)
  }

  redirectToHostedUI = () => {
    const encodedUri = encodeURIComponent(redirectUri)
    const cognitoHostedURL = `https://haloclip.auth.us-west-2.amazoncognito.com/login?client_id=${clientId}&response_type=code&scope=email+openid+phone&redirect_uri=${encodedUri}`
    window.location.href = cognitoHostedURL
  }

  handleLogout = () => {
    sessionStorage.removeItem('idToken')
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.setItem('isLoggedIn', 'false')
    window.location.reload()
  }

  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0], fileUploadedSuccessfully: false })
  }

  onFileUpload = async () => {
    if (!this.state.selectedFile) {
      return
    }

    this.setState({ uploading: true })

    // call api to get presigned url
    const presignResponse = await fetch(ENDPOINT + '/getPresignedUrl', {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${sessionStorage.getItem('idToken')}`
      }
    })

    // get response body and parse JSON
    const responseJSON = await presignResponse.json()
    const responseBody = JSON.parse(responseJSON.body)

    // read the file as a binary array
    const reader = new FileReader()
    reader.readAsArrayBuffer(this.state.selectedFile)

    // upload mp4 with presigned URL
    reader.onload = async () => {
      const uploadResponse = await fetch(responseBody.uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "video/mp4",
          "Access-Control-Allow-Origin": "*"
        },
        body: reader.result
      })

      // add filename and username to rds if upload successful
      if (uploadResponse.ok) {
        await fetch(ENDPOINT + '/addUserUpload', {
          method: 'PUT',
          headers: {
            "Authorization": `Bearer ${sessionStorage.getItem('idToken')}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({filename: responseBody.filename})
        })
        .then(() => {
          // update state only if the second fetch is successful
          this.setState({selectedFile: null, uploading: false, fileUploadedSuccessfully: true})
        })
        .catch(error => {
          console.error("Error in second fetch:", error)
          this.setState({selectedFile: null, uploading: false, fileUploadedSuccessfully: false})
        })
      } else {
        console.error("Error in first fetch:", uploadResponse.statusText)
      }
    }
  }

  onClipRequest = async () => {
    try {
      this.setState({ loadingClips: true })

      // call api to get presigned clip urls
      const clipUrlsResponse = await fetch(ENDPOINT + '/getClipUrls', {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('idToken')}`
        }
      })

      // get the response and loop through all clip urls
      const response  = await clipUrlsResponse.json()
      this.setState({ clipUrls: response.body, loadingClips: false })

    } catch (error) {
      console.error('Error fetching clip URLs:', error)
      this.setState({ loadingClips: false })
    }
  }

  onClipDelete = async (clipname) => {
    try {
      // call api to get delete selected clip
      await fetch(ENDPOINT + '/deleteClip/' + clipname, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${sessionStorage.getItem('idToken')}`
        }
      })

    } catch (error) {
      console.error('Error deleting clip:', error)
    }

    // call onClipRequest to refresh clip URLs
    this.onClipRequest()
  }

  fileData = () => {
    if (this.state.uploading) {
      return (
        <div>
          <h4 className="mt-2 text-sm leading-8 text-gray-300 mb-4">Uploading...</h4>
        </div>
      )
    } else if (this.state.selectedFile) {
      return (
        <div className="mt-6 border-t border-white/10">
          <dl className="divide-y divide-white/10">
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">File Name:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{this.state.selectedFile.name}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">File type:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{this.state.selectedFile.type}</dd>
            </div>
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-white">Last modified:</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-400 sm:col-span-2 sm:mt-0">{this.state.selectedFile.lastModifiedDate.toDateString()}</dd>
            </div>
          </dl>
        </div>
      )
    } else if (this.state.fileUploadedSuccessfully) {
      return (
        <div>
          <h4 className="mt-2 text-sm leading-8 text-gray-300 mb-4">Your file has been successfully uploaded!</h4>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="flex flex-col items-center min-h-screen ax-w-7xl sm:px-32 bg-gray-800 bg-gray-800 p-20 font-mono">
        {/* header */}
        <div className="mb-10 mt-2 max-w-4xl w-full">
          <h1 className="text-4xl font-bold text-center tracking-tight text-white sm:text-4xl font-serif">Halo, Clip Me</h1>
          <p className="mt-6 text-sm leading-8 text-gray-300">Hello, welcome to halo clip me! Generate multikill clips by uploading your halo infinite gameplay.</p>
        </div>
        {/* cognito log-in form */}
        {sessionStorage.getItem('isLoggedIn') !== 'true' ? (
          <div className="mb-6">
            <button
              type="button"
              onClick={this.redirectToHostedUI}
              className="rounded-full bg-violet-900 px-4 py-2 ml-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring focus:border-blue-300"
              >
                Log In
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            {/* add new gameplay button */}
            <div className="flex flex-end">
              <input type="file" accept=".mp4" onChange={this.onFileChange}
                className="block w-full text-xs text-transparant
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-violet-900
                hover:file:bg-violet-200
              "/>
              <button type="button" onClick={this.onFileUpload}
                className="rounded-full bg-violet-900 px-4
                py-2 text-sm font-semibold text-white shadow-sm
                hover:bg-indigo-500 focus-visible:outline
                focus-visible:outline-2 focus-visible:outline-offset-2
                focus-visible:outline-violet-900">Upload</button>
            </div>
            <div className="mt-6 mb-4">
              {this.fileData()}
            </div>
            {/* clips from videos */}
            <div className="mb-12">
              {this.state.loadingClips ? (
                <p className="mt-3 text-sm leading-8 text-gray-300">Loading clips...</p>
              ) : (
                this.state.clipUrls && this.state.clipUrls.length === 0 ? (
                  <p className="mt-3 text-sm leading-8 text-gray-300">No clips to show.</p>
                ) : (
                  <ul className="mt-6 max-w-6xl grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {this.state.clipUrls && this.state.clipUrls.map((url, index) => (
                      <li key={index} className="relative">
                        <div>
                          <div className="group block w-full overflow-hidden rounded-lg bg-gray-80 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                            <video controls width="100%" height="100%">
                              <source src={url} type="video/mp4"/>
                              Your browser does not support the video tag.
                            </video>
                          </div>
                          <p className="pointer-events-none mt-2 block truncate text-xs font-medium text-white">{url.match(/\/clips\/([^/?]+_clip_\d\.mp4)/i)[1]}</p>
                          <div className="flex justify-between">
                            <a href={url} download={`video_${index + 1}.mp4`} className="text-sm font-medium text-gray-400 underline">
                              Download
                            </a>
                            <button type="button" className="text-sm font-medium text-red-600 pr-2" onClick={() => this.onClipDelete(url.match(/\/clips\/([^/?]+_clip_\d)/i)[1])}>
                              Delete
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )
              )}
            </div>
            <button type="button" onClick={this.onClipRequest}
              className="rounded-full bg-violet-900 px-4
              py-2 text-sm font-semibold text-white shadow-sm
              hover:bg-indigo-500 focus-visible:outline
              focus-visible:outline-2 focus-visible:outline-offset-2
              focus-visible:outline-violet-900">Refresh My Clips</button>
            <button type="button" onClick={this.handleLogout}
              className="mt-12 rounded-full bg-red-900 px-4
              py-2 text-sm font-semibold text-white shadow-sm
              hover:bg-red-600 focus-visible:outline
              focus-visible:outline-2 focus-visible:outline-offset-2
              focus-visible:outline-red-900">Logout</button>
          </div>
        )}
      </div>
    )
  }
}

export default App
